import { Component, Mixins } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../support/mixins'
import { TrustedShopReviewsMixin } from '../../../shared/mixins/trustedShopReviewsMixin'

/**
 * NOTE! This helper mixin overcomes native 'vue-property-decorator' which Mixins accepts only 5 arguments
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'ProductHeaderHelperMixin'
})
export class ProductHeaderHelperMixin extends Mixins(
  StructureConfigurable,
  TrustedShopReviewsMixin
) {}
