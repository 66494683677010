




































































































































































































































































































































import { Component, Inject as VueInject, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  IModal,
  mapModel,
  ModalType
} from '@movecloser/front-core'

import { ShapeMap, SizeMap, VariantMap } from '../../../../dsl/composables'
import {
  calculateDiscount,
  defaultProvider,
  Inject,
  logger,
  IS_MOBILE_PROVIDER_KEY
} from '../../../../support'
import {
  AllowedAttributes,
  AttributeValue,
  ProductData,
  Variant as ProductVariant
} from '../../../../contexts'
import { StarsRateProps } from '../../../../dsl/molecules/StarsRate'

import { ProductReviewsID } from '../../../../modules/ProductReviews/ProductReviews.config'

import { BenefitsBar } from '../../../shared/molecules/BenefitsBar'
import {
  DrawerType,
  IDrawer,
  IStoreService,
  StoreServiceType
} from '../../../shared/contracts/services'
import { Gallery } from '../../../shared/molecules/Gallery'
import { GalleryProps } from '../../../shared/molecules/Gallery/Gallery.contracts'
import { MilesAndMoreCounter } from '../../../loyalty/molecules/MilesAndMoreCounter'
import { openAuthDrawer, UserModel } from '../../../auth/shared'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'
import { ToastMixin } from '../../../shared'
import { ILocaleContentManager, LocaleContentManagerType, ToastType } from '../../../shared/services'
import {
  translateProductVariantToGalleryProps
} from '../../../shared/molecules/Gallery/Gallery.helpers'
import WindowScrollMixin from '../../../shared/mixins/windowScroll.mixin'

import { attributesAdapterMap } from '../../models/attributes.adapter'
import { FavouriteProductsServiceType, IFavouriteProductsService } from '../../contracts/services'
import { Modals } from '../../config/modals'
import { NotificationForm } from '../../molecules/NotificationForm'
import { IProductsRepository, ProductsRepositoryType } from '../../contracts/repositories'
import { translateProductVariantToStarsRateProps } from '../../helpers/start-rate'
import {
  translateProductVariantsToVariantsSwitch,
  Variant,
  VariantsSwitch
} from '../../molecules/VariantsSwitch'
import { units } from '../../config/units'

import { isAttribute } from '../ProductCard/ProductCard.helpers'

import AllowedAttributesIcons from './partials/AllowedAttributesIcons.vue'
import { AllowedCertificates } from './partials/AllowedCertificates.vue'
import { GiftBox } from './partials/GiftBox.vue'
import {
  Application,
  ProductHeaderIcons,
  ProductHeaderProps,
  ShippingTimerData,
  Slug
} from './ProductHeader.contracts'
import {
  PRODUCT_HEADER_COMPONENT_KEY,
  PRODUCT_HEADER_DEFAULT_CONFIG
} from './ProductHeader.config'
import DeliveryTimer from './partials/DeliveryTimer.vue'
import OrderDetails from './partials/OrderDetails.vue'

import PriceNameBar from './partials/PriceNameBar.vue'
import { BaseWishListMixin, IBaseWishListMixin } from '../../../wishlist/shared/mixins/base.mixin'
import { showDeliveryTimer } from '../../../shared/support/delivery-timer'
import VariantDetailsRating from './partials/VariantDetailsRating.vue'
import { ProductHeaderHelperMixin } from './ProductHeader.mixin'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ProductHeader>(
  {
    name: 'ProductHeader',
    components: {
      AllowedAttributesIcons,
      AllowedCertificates,
      BenefitsBar,
      DeliveryTimer,
      GiftBox,
      OrderDetails,
      Gallery,
      MilesAndMoreCounter,
      NotificationForm,
      VariantsSwitch,
      PriceNameBar,
      VariantDetailsRating
    },
    async created (): Promise<void> {
      this.config = this.getComponentConfig(
        PRODUCT_HEADER_COMPONENT_KEY,
        { ...PRODUCT_HEADER_DEFAULT_CONFIG }
      )
      this.setVariantOnMount()
      this.initShowTimer()
      await this.checkGiftsAvailability()

      if (this.useVendorReviews) {
        await this.loadReviewsBySku()
      }
    },
    mounted (): void {
      this.eventBus.emit('app:product.view', this.getProductViewPayload(this.variant))

      this.checkIsFavoriteVariant()
      this.createPriceNameObserver()
    }
  })

export class ProductHeader extends Mixins<
  ProductCartMixin,
  ProductHeaderHelperMixin,
  ToastMixin,
  WindowScrollMixin,
  IBaseWishListMixin
  >(
    ProductCartMixin,
    ProductHeaderHelperMixin,
    ToastMixin,
    WindowScrollMixin,
    BaseWishListMixin) implements ProductHeaderProps {
  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public readonly shippingTimer!: ShippingTimerData

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(LocaleContentManagerType, false)
  protected readonly localeContentManager?: ILocaleContentManager

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(StoreServiceType, false)
  protected readonly storeService?: IStoreService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  public addToFavoriteBtnLoading: boolean = false

  public addToCartBtnLoading: boolean = false
  public currentVariantSlug: Slug | null = null
  public currentVariant: ProductVariant<string> | null = null
  public hasUnavailableGifts: boolean = false

  public isFavorite: boolean | undefined = false
  public isNotificationFormVisible: boolean | undefined = false

  public ratingAmountDefault: number = 0
  public ratingAvgDefault: number = 0
  public limitHours: string | null = null

  // todo: units - config?
  public variantUnit: string = ''
  public variantVolume: number = 0
  public units: any = units
  public renderPriceNameBar: boolean = false
  public showTimer: boolean = false

  // todo: aelia 4
  public readonly LAST_ITEMS_AMOUNT: number = 3

  public readonly TOOLTIP_DELIVERY_INFO: string = this.$t(
    'front.products.organisms.productHeader.tooltipInfoDelivery').toString()

  @Ref('productHeader')
  public productHeaderRef!: HTMLDivElement

  @Ref('productHeaderContent')
  public productHeaderContentRef!: HTMLDivElement

  /**
   * Determines the application of current product
   */
  protected get applicationOptions (): string[] | null {
    const application: string | undefined = this.getAttribute<string>(AllowedAttributes.Application)

    if (typeof application === 'undefined') {
      return null
    }

    if (application.includes('/')) {
      return application.split('/')
    }

    return [application]
  }

  public get badges () {
    const badges = []

    if (this.isFinalPriceDifferent) {
      if (this.getAttribute('isSale')) {
        badges.push({
          label: this.$t('front.products.organisms.productHeader.attributes.isSale').toString(),
          theme: this.badgeThemes[AllowedAttributes.IsSale] ?? 'primary',
          shape: ShapeMap.Rectangle,
          variant: VariantMap.Full,
          size: SizeMap.Medium
        })
      } else if (!this.getAttribute('isSale') && this.shouldDisplayDiscount) {
        const theme = this.badgeThemes[AllowedAttributes.IsPromotion] ? this.badgeThemes[AllowedAttributes.IsPromotion] as string : this.promotionBadgeHasLabel ? 'danger' : 'primary'
        const discountValue = `-${100 - (Math.round((this.variant.price.finalPrice /
          this.variant.price.regularPrice) * 100))}%`
        const label = this.promotionBadgeHasLabel ? discountValue : this.$t(
          'front.products.organisms.productHeader.attributes.isPromotion').toString()
        const shape = this.promotionBadgeHasLabel ? ShapeMap.Square : ShapeMap.Rectangle

        badges.push({
          label,
          theme,
          shape,
          variant: VariantMap.Full,
          size: SizeMap[this.isMobile() ? 'Small' : 'Large']
        })
      }
    }

    if (this.getAttribute('isNatural')) {
      badges.push({
        icon: 'Leaf',
        theme: 'success',
        shape: ShapeMap.Square,
        variant: VariantMap.Outline,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('hasFreeDelivery')) {
      badges.push({
        icon: 'FreeDelivery',
        theme: '',
        shape: ShapeMap.Square,
        variant: VariantMap.Outline,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('isBestseller')) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isBestseller').toString(),
        theme: this.badgeThemes[AllowedAttributes.IsBestseller] ?? 'flat',
        shape: this.isNewBadgeSquare ? ShapeMap.Square : ShapeMap.Rectangle,
        variant: VariantMap.Full,
        size: this.isNewBadgeSquare ? (SizeMap[this.isMobile() ? 'Small' : 'Large']) : SizeMap.Medium
      })
    }

    if (this.getAttribute('isNew')) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isNew').toString(),
        theme: this.badgeThemes[AllowedAttributes.IsNew] ?? 'primary',
        shape: this.isNewBadgeSquare ? ShapeMap.Square : ShapeMap.Rectangle,
        variant: VariantMap.Full,
        size: this.isNewBadgeSquare ? (SizeMap[this.isMobile() ? 'Small' : 'Large']) : SizeMap.Medium
      })
    }

    if (this.getAttribute('isFaF')) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isFaF').toString(),
        theme: 'premium',
        shape: ShapeMap.Square,
        variant: VariantMap.Full,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    if (this.getAttribute('isPresale')) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isPresale').toString(),
        theme: 'warning',
        shape: ShapeMap.Square,
        variant: VariantMap.Full,
        size: SizeMap[this.isMobile() ? 'Small' : 'Large']
      })
    }

    return badges
  }

  /**
   * Determines the certification attributes for the variant.
   */
  public get allowedCertificates (): Record<string, boolean> {
    return {
      [AllowedAttributes.CertFSC]: this.getAttribute<boolean>(AllowedAttributes.CertFSC) ?? false,
      [AllowedAttributes.CertCrueltyFree]: this.getAttribute<boolean>(AllowedAttributes.CertCrueltyFree) ?? false,
      [AllowedAttributes.CertVegan]: this.getAttribute<boolean>(AllowedAttributes.CertVegan) ?? false,
      [AllowedAttributes.CertECOCET]: this.getAttribute<boolean>(AllowedAttributes.CertECOCET) ?? false
    }
  }

  public get isCartAvailableForLocale (): boolean {
    if (!this.localeContentManager) {
      return true
    }

    return this.localeContentManager.retrieve<boolean>('cart')
  }

  public get wishlistBtnTitle (): string {
    return this.$t(`front.shared.wishlist.${this.isFavorite ? 'remove' : 'add'}`).toString()
  }

  public calculatedDiscount (finalPrice: number, regularPrice: number): string {
    return calculateDiscount(finalPrice, regularPrice)
  }

  public get badgeThemes (): Record<string, string | undefined> {
    return this.getConfigProperty('badgeThemes')
  }

  public get hasCertificates (): boolean {
    return this.getConfigProperty('hasCertificates')
  }

  public get hasVariantDetailsAttributes (): boolean {
    return this.getConfigProperty('hasVariantDetailsAttributes')
  }

  public get previousPriceConfig (): Record<string, unknown> {
    return this.getConfigProperty('previousPriceConfig')
  }

  /**
   * Determines whether description should be visible in the `AddReviewModal`
   */
  public get shouldDisplayReviewWithDescription (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayReviewWithDescription')
  }

  public get shouldDisplayDiscount (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayDiscount')
  }

  public get shouldDisplayMilesAndMore (): boolean {
    return this.getConfigProperty('shouldDisplayMilesAndMore')
  }

  public get shouldDisplayRegularPriceForDiscount (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayRegularPriceForDiscount')
  }

  public get shouldDisplayApplication (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayApplication')
  }

  public get shouldDisplayUnits (): boolean {
    return this.getConfigProperty<boolean>('shouldDisplayUnits')
  }

  public get isNewBadgeSquare (): boolean {
    return this.getConfigProperty<boolean>('isNewBadgeSquare')
  }

  public initialVariantSlug (): Slug {
    const slug = this.$route.query.variant
    let slugs: Record<string, string> = {}
    let full: string

    if (!slug || Array.isArray(slug)) {
      const selectors = Object.entries(this.product.variantSelector || {})
      const parts: string[] = []

      for (const [key, selector] of selectors) {
        if (selector.length > 0) {
          if (selector[0] && 'slug' in selector[0]) {
            slugs[key] = selector[0].slug
            parts.push(selector[0].slug)
          }
        }
      }

      full = parts.length ? parts.join('-') : '_'
    } else {
      slugs = this.product.variants[slug].identifier
      full = slug
    }

    return {
      ...slugs,
      full
    }
  }

  protected initShowTimer () {
    if (!this.siteService) {
      return
    }

    const shippingTimer = this.siteService.getProperty('shippingTimer') as ShippingTimerData | undefined
    const { shouldShowTimer } = showDeliveryTimer(shippingTimer)

    this.showTimer = shouldShowTimer
    this.limitHours = shippingTimer ? shippingTimer.limitHours : '17'
  }

  public get variantRating (): number {
    return this.variant.rating?.average.rate ?? this.ratingAmountDefault
  }

  public get ratingAmount (): number {
    return this.variants.map((variant) =>
      variant.rating?.average.amount ?? this.ratingAmountDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get ratingAvg (): number {
    return this.variants.map((variant) =>
      variant.rating?.average.rate ?? this.ratingAmountDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get canAddToCart (): boolean {
    return this.variant.isAvailable && this.variant.sellableQuantity > 0
  }

  public get deliveryTimeInfo () {
    if (!this.storeService) {
      return null
    }

    try {
      const info = this.storeService.deliveryInfo
      const day = this.$t(`front.products.organisms.productHeader.infoBarEntryDays.${info.day}`)

      return {
        ...info,
        day
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    }
  }

  public get galleryProps (): GalleryProps {
    return {
      ...translateProductVariantToGalleryProps(this.variant, this.isMobile()),
      badges: this.badges
    }
  }

  public get hasAdvancedVariants (): boolean {
    if (!this.product.variantSelector) {
      return false
    }

    return Object.keys(this.product.variantSelector).some(v => this.variantsSwitchType(v) ===
      'advanced')
  }

  public get hasGiftsBox (): boolean {
    return this.getConfigProperty('hasGiftsBox') &&
      this.currentVariant?.attributes[AllowedAttributes.HasGift] as boolean &&
      this.hasUnavailableGifts
  }

  public get averageTrustedShopsProductRating (): number {
    return this.$store.getters['products/getAverageProductRating']
  }

  /**
   * Determines whether to use review from external vendor
   */
  public get useVendorReviews (): boolean {
    return this.getConfigProperty('useVendorReviews')
  }

  public get defaultMaxRating (): number {
    return this.getConfigProperty('defaultMaxRating')
  }

  public get shouldHaveVisibleRating (): boolean {
    return this.getConfigProperty('shouldHaveVisibleRating')
  }

  /**
   * Determines whether product header gallery has badges.
   */
  public get badgesOnGallery (): boolean {
    return this.getConfigProperty('badgesOnGallery')
  }

  public get favouriteAsIcon (): boolean {
    return this.getConfigProperty('favouriteAsIcon')
  }

  public get isVariantNameEnabled (): boolean {
    return this.getConfigProperty('isVariantNameEnabled')
  }

  /**
   * Determines whether product header has delivery timer.
   */
  public get hasDeliveryTimer (): boolean {
    return this.getConfigProperty('hasDeliveryTimer')
  }

  /**
   * Determines the capacity based attributes of the variant (ex: volume, weight)
   */
  public get displayableCapacityAttributes (): string[] {
    const toReturn: string[] = []

    if (this.shouldDisplayVolume) {
      toReturn.push('volumeName')
    }

    if (this.shouldDisplayWeight) {
      toReturn.push('weightName')
    }

    return toReturn
  }

  /**
   * Determines whether product header has a "notify me" button.
   */
  public get hasNotificationForm (): boolean {
    return this.getConfigProperty('hasNotificationForm')
  }

  /**
   * Determines whether product should have 'default' VariantSelector component regardless of other conditions
   */
  public get hasDefaultVariantSelector (): boolean {
    return this.getConfigProperty('hasDefaultVariantSelector')
  }

  /**
   * Determines whether product header has order details.
   */
  public get hasOrderDetails (): boolean {
    return this.getConfigProperty('hasOrderDetails')
  }

  /**
   * Determines whether promotion badge has label with discount value.
   */
  public get promotionBadgeHasLabel (): boolean {
    return this.getConfigProperty('promotionBadgeHasLabel')
  }

  /**
   * Determines whether chosen variant value is showed.
   */
  public get variantSwitcherShowChosen (): boolean {
    return this.getConfigProperty('variantSwitcherShowChosen')
  }

  /**
   * Determines whether to show rating only for current variant instead of aggregate
   */
  public get showSingleVariantRating (): boolean {
    return this.getConfigProperty('showSingleVariantRating')
  }

  public get icons (): ProductHeaderIcons {
    return this.getConfigProperty('icons')
  }

  /**
   * Determines whether rating should be formated as: "rate / maximum rate" instead of "rate (maximum rate)"
   */
  public get shouldHaveSeparatedRating (): boolean {
    return this.getConfigProperty<boolean>('shouldHaveSeparatedRating')
  }

  public get shouldDisplayVolume (): boolean {
    return !!this.variant.attributes.volumeName
  }

  public get shouldDisplayWeight (): boolean {
    return !!this.variant.attributes.weightName
  }

  public get hasDiscount (): boolean {
    if (!this.variant) {
      return false
    }
    const {
      finalPrice,
      regularPrice
    } = this.variant.price

    return finalPrice < regularPrice
  }

  public get hasVariants (): boolean {
    return Object.keys(this.product.variants).length > 1
  }

  public get variantBrandUrl (): string {
    return this.variant.attributes[AllowedAttributes.BrandUrl]?.toString() ??
      this.variant.attributes[AllowedAttributes.ProductLineUrl] ?? ''
  }

  public get variantCategoryUrl (): string {
    return this.variant.attributes[AllowedAttributes.MainCategoryUrl].toString()
  }

  public variantsSwitchType (key: string): string {
    if (this.hasDefaultVariantSelector) {
      return 'default'
    }

    if (!this.product.variantSelector || !(key in this.product.variantSelector)) {
      throw new Error('key not found')
    }

    const elements = this.product.variantSelector[key]

    if (elements.length === 0) {
      return ''
    }

    if (elements.some(({ value }) => !!value)) {
      return 'default'
    }

    return 'advanced'
  }

  public get isFavoriteVariant (): boolean | undefined {
    return this.isFavorite
  }

  /**
   * Determines the whether final price is different.
   */
  public get isFinalPriceDifferent (): boolean {
    return this.hasDiscount
  }

  public get productReviewsIdentifier () {
    return ProductReviewsID
  }

  public get starsRateProps (): Omit<StarsRateProps, 'model'> {
    return translateProductVariantToStarsRateProps(this.variant, false)
  }

  public get variant (): ProductVariant<string> {
    if (!this.currentVariant) {
      throw new Error('Variant not set')
    }

    return {
      ...this.currentVariant,
      attributes: mapModel(this.currentVariant.attributes, attributesAdapterMap, false)
    }
  }

  public variantLabel (key: string, variant: ProductVariant<string>): string | undefined {
    if (!this.product.variantSelector) {
      return ''
    }

    return this.product.variantSelector[key].find((selector) => {
      return (selector && 'slug' in selector) && selector.slug === variant.identifier[key]
    })?.label
  }

  public get variantLastItems (): boolean {
    return this.variant.sellableQuantity <= this.LAST_ITEMS_AMOUNT
  }

  public get variants (): ProductVariant<string>[] {
    return Object.values(this.product.variants)
  }

  public get modalSize (): string {
    return this.getConfigProperty<string>('modalSize')
  }

  public get useDrawer (): boolean {
    return this.getConfigProperty<boolean>('useDrawer')
  }

  public hideTimer (): void {
    this.showTimer = false
  }

  public variantsSwitchProps (type = 'color'): Variant[] {
    return translateProductVariantsToVariantsSwitch(this.product, type)
  }

  public async onAddToCart (): Promise<void> {
    if (!this.cartService) {
      return
    }

    this.addToCartBtnLoading = true

    try {
      await this.addToCart(
        this.variant,
        1,
        true,
        this.modalSize,
        this.isMobile() ? this.useDrawer : false
      )
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToCartBtnLoading = false
    }
  }

  public onNotifyMe (): void {
    this.isNotificationFormVisible = true
  }

  public async addToFavorite (): Promise<void> {
    this.addToFavoriteBtnLoading = true

    try {
      await this.add({
        sku: this.variant.sku,
        quantity: 1
      })
      this.isFavorite = true
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  public authCheck (): boolean {
    return this.authService?.check() || false
  }

  public async checkIsFavoriteVariant (): Promise<void> {
    if (!this.variant || typeof this.variant === 'undefined') {
      return
    }

    this.isFavorite = this.isInWishlist(this.variant.sku)
  }

  public generateCategoryLink (categoryTree: AnyObject, name: string): string {
    if (categoryTree && categoryTree.parent) {
      return this.generateCategoryLink(categoryTree.parent, `${categoryTree.slug}/${name}`)
    } else {
      return `/${name.toLowerCase().slice(0, -1)}`
    }
  }

  /**
   *
   * TODO: Should be operated by key, not by text.
   * Gets the application of the active variant.
   */
  public getApplication (application: string): undefined | string {
    const day = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.day}`)
    const night = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.night}`)
    const dayNight = this.$t(`front.products.organisms.productHeader.attributes.application.${Application['day/night']}`)
    switch (application) {
      case day:
        return 'DayIcon'
      case night:
        return 'NightIcon'
      case dayNight:
        return 'DayNightIcon'
    }
  }

  public async handleFavoriteAction (): Promise<void> {
    if (!this.isWaitingForAuth && !this.authService?.check() && this.drawerConnector) {
      openAuthDrawer(this.drawerConnector)
      return
    }

    if (!this.isFavorite) {
      return await this.addToFavorite()
    }

    await this.removeFromFavorite()
  }

  public leaveReview (): void {
    if (!this.authCheck() && this.drawerConnector) {
      return openAuthDrawer(this.drawerConnector)
    }

    const variantWithColor = this.product.variantSelector?.color?.find(({ slug }) => {
      return slug === this.variant.identifier.color
    })
    this.modalConnector.open(Modals.AddReviewModal, {
      title: this.variant.attributes[AllowedAttributes.ProductLine],
      description: this.shouldDisplayReviewWithDescription ? this.variant.name : '',
      variantHex: variantWithColor?.value ?? '',
      variant: 'color',
      sku: this.variant.sku
    })
  }

  public onUpdateVariant (slug: string, type = 'color') {
    if (!this.currentVariantSlug) {
      throw new Error('Variant slug not set')
    }

    const currentSlug = this.currentVariantSlug
    const newSlug: string[] = []
    const selectorKeys = Object.keys(this.product.variantSelector || {})
    selectorKeys.forEach((key) => {
      if (key === type) {
        newSlug.push(slug)
        return
      }

      newSlug.push(currentSlug[key])
    })

    this.currentVariantSlug[type] = slug
    slug = newSlug.join('-')

    this.setVariant(slug)
    this.checkIsFavoriteVariant()

    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        variant: slug
      }
    })
  }

  public get calculateVariantVolume () {
    if (!this.variant.attributes.size) {
      this.variantVolume = 1
      this.variantUnit = 'szt.'
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const capacity = this.variant.attributes.size.split(' ')
      this.variantVolume = capacity[0]
      this.variantUnit = capacity[1]
    }

    if (this.units[this.variantUnit] === undefined) {
      return false
    }

    const u = this.units[this.variantUnit].volume
    const volume = (u * this.variant.price.finalPrice) / this.variantVolume

    return volume.toFixed(2)
  }

  public get calculateBasicPricePercents () {
    const calc = Math.round(100 - ((this.variant.price.finalPrice * 100) / this.variant.price.previousPrice))
    return this.calculatePercents(calc)
  }

  public get calculateOmnibusPercents () {
    if (!this.variant.price.previousPrice) {
      return this.calculatePercents(0)
    }
    const calc = Math.round(100 - ((this.variant.price.finalPrice * 100) / this.variant.price.regularPrice))
    return this.calculatePercents(calc)
  }

  public calculatePercents (value: number) {
    if (value === 0) {
      return value + '%'
    }

    if (value < 100 && value > 0) {
      return '-' + value + '%'
    }

    return '+' + Math.abs((value)) + '%'
  }

  public setVariant (slug: string): void {
    const foundVariant = this.product.variants[slug]
    if (!foundVariant) {
      return
    }

    this.currentVariant = foundVariant
  }

  public setVariantOnMount (): void {
    this.currentVariantSlug = this.initialVariantSlug()
    this.setVariant(this.currentVariantSlug.full)
  }

  public async removeFromFavorite (): Promise<void> {
    this.addToFavoriteBtnLoading = true

    try {
      await this.remove(this.variant.sku)
      this.isFavorite = false
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  protected createPriceNameObserver (): void {
    if (!this.productHeaderContentRef) {
      return
    }

    const observer = new IntersectionObserver(this.handlePriceNameDisplay, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    })
    observer.observe(this.productHeaderContentRef)
  }

  protected handlePriceNameDisplay (entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      this.renderPriceNameBar = !entry.isIntersecting
    })
  }

  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.variant || typeof this.variant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.variant.attributes
      ? this.variant.attributes[attribute] as R : undefined
  }

  protected notify (message: string, type: ToastType): void {
    this.showToast(message, type)
  }

  /**
   * Checks whether every possible gifts are unavailable. Necessary to hide `GiftsBox` partial
   * @protected
   */
  protected async checkGiftsAvailability (): Promise<void> {
    if (!this.currentVariant) {
      return
    }

    const possibleGifts = this.currentVariant.attributes[AllowedAttributes.GiftsSku] as string[]

    if (!possibleGifts || possibleGifts.length === 0) {
      return
    }

    try {
      const gifts = await this.productsRepository.loadProductsBySkus(possibleGifts)

      if (!gifts || gifts.length === 0) {
        return
      }

      this.hasUnavailableGifts = gifts.every((gift) => Object.values(gift.variants)[0].isAvailable && Object.values(gift.variants)[0].sellableQuantity > 0)
    } catch (e) {
      logger(e, 'warn')
    }
  }

  /**
   * Loads TrustedShop product reviews by sku
   * @protected
   */
  protected async loadReviewsBySku (): Promise<void> {
    if (!this.product) {
      return
    }

    this.eventBus.emit('product:trustedShop-global-loading', true)

    const skus: string[] = []

    for (const variant of Object.values(this.product.variants)) {
      skus.push(variant.sku)
    }

    if (skus.length === 0) {
      return
    }

    try {
      await this.loadProductReviews(skus)
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.eventBus.emit('product:trustedShop-global-loading', false)
    }
  }

  @Watch('wishlist')
  private onWishlist (): void {
    if (this.wishlist) {
      this.checkIsFavoriteVariant()
    }
  }
}

export default ProductHeader
