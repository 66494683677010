




































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'

import { ConnectorErrors, Inject, logger } from '../../../../support'
import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'

import { AuthMixin, IAuthMixin } from '../../../auth/shared'
import { Form } from '../../../shared/molecules/Form'
import { IToastMixin, ToastMixin } from '../../../shared'
import { UserMixin } from '../../../profile/shared'

import {
  NOTIFICATION_FORM_COMPONENT_KEY,
  NOTIFICATION_FORM_DEFAULT_CONFIG
} from './NotificationForm.config'
import { IProductStockRepository, ProductStockRepositoryType } from '../../contracts/repositories'

import { notificationFormValidationsMap } from './NotificationForm.helpers'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<NotificationForm>({
  name: 'NotificationForm',
  components: { Form },
  created () {
    this.config = this.getComponentConfig(
      NOTIFICATION_FORM_COMPONENT_KEY,
      NOTIFICATION_FORM_DEFAULT_CONFIG
    )

    /**
     * @inheritDoc
     */
    this.fillFormDataEmail()
  }
})
export class NotificationForm extends Mixins<FormErrorsMixin, IAuthMixin, IToastMixin, StructureConfigurable, UserMixin>(
  FormErrorsMixin,
  AuthMixin,
  ToastMixin,
  StructureConfigurable,
  UserMixin
) {
  @Inject(ProductStockRepositoryType)
  private productStockRepository!: IProductStockRepository

  @Prop({ type: String, required: true })
  public readonly sku!: string

  public globalError: string | null = null
  public isLoading: boolean = false
  public isThankYou: boolean = false
  public payload = {
    sku: this.sku,
    email: ''
  }

  public validatorsMap = notificationFormValidationsMap

  public onFail (error: Error): void {
    this.setError(error)
  }

  public async onSubmit (): Promise<void> {
    this.cleanGlobalError()

    try {
      this.isLoading = true

      await this.productStockRepository.subscribeToStockNotification(this.payload)
    } catch (error) {
      logger(error, 'warn')
      this.setError(error as Error)
    } finally {
      this.isLoading = false
    }
  }

  public onSuccess (): void {
    this.isThankYou = true
  }

  public updateEmail (email: string): void {
    this.payload = { ...this.payload, email }
  }

  public onClose () {
    this.isThankYou = false
    this.$emit('onClose')
  }

  protected cleanGlobalError (): void {
    this.globalError = null
  }

  protected setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.globalError = error.message
  }

  /**
   * Sets formData email key for logged-in users
   * @protected
   */
  protected fillFormDataEmail (): void {
    if (this.isLoggedInUser && this.user) {
      this.payload = {
        ...this.payload,
        email: this.user.email
      }
    }
  }
}

export default NotificationForm
