/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const GALLERY_COMPONENT_KEY = 'GalleryComponent'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const GALLERY_COMPONENT_CONFIG_MAP = {
  closeIcon: 'CloseIcon',
  hasBadges: true
}
