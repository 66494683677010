







import { Component, Prop, Vue } from 'vue-property-decorator'

import { ImageProps } from '../../../../../dsl/atoms/Image'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ImageZoom>({
  name: 'ImageZoom'
})
export class ImageZoom extends Vue {
  @Prop({ required: true, type: Object })
  image!: ImageProps

  public isActiveZoom: boolean = false

  public deactiveZoom () {
    this.isActiveZoom = false
  }

  public onImageClick (e: PointerEvent | MouseEvent) {
    if (!this.isActiveZoom) {
      this.isActiveZoom = true
      this.zoomImage(e)
    } else {
      this.deactiveZoom()
    }
  }

  public onMouseMove (e: PointerEvent | MouseEvent) {
    this.zoomImage(e)
  }

  public zoomImage (e: PointerEvent | MouseEvent) {
    const imageElement = e.target as HTMLElement

    if (!imageElement) {
      return
    }

    const { left: offsetX, top: offsetY, width, height } = imageElement.getBoundingClientRect()

    const relativeX = e.clientX - offsetX
    const relativeY = e.clientY - offsetY

    const transformX = (relativeX / width) * 100
    const transformY = (relativeY / height) * 100

    imageElement.style.transformOrigin = `${transformX}% ${transformY}%`
  }
}

export default ImageZoom
