



















import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component({ name: 'AllowedCertificates' })
export class AllowedCertificates extends Vue {
  @Prop({ type: Object, required: true })
  public readonly certificates!:Record<string, boolean>

  /**
   * Determines whether at least one certificate is set to true.
   */
  public get hasAtLeastOne (): boolean {
    return Object.values(this.certificates).some(cert => cert)
  }
}

export default AllowedCertificates
