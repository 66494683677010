import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<TopBarMixin>({
  name: 'TopBarMixin'
})
export class TopBarMixin extends Vue {
  public get hasTopBarVisible () {
    return this.$store.getters['shared/getHasTopBar']
  }
}

export default TopBarMixin
