var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gallery",class:{'--desktop':!_vm.isMobile()}},[(!_vm.isMobile())?[(_vm.thumbnails.length)?_c('CustomThumbnails',_vm._b({attrs:{"direction":"vertical"},on:{"change":_vm.onThumbnailChange}},'CustomThumbnails',{
        direction: _vm.direction,
        thumbnails: _vm.thumbnails,
        selectedImageIndex: _vm.selectedImageIndex,
        carouselConfiguration: _vm.thumbnailsCarouselConfiguration
      },false)):_vm._e(),_c('CustomImageZoom',{attrs:{"image":_vm.fullImage}})]:_vm._e(),(_vm.isMobile())?[(_vm.badges && _vm.displayBadges)?_c('div',{staticClass:"variant-badges"},_vm._l((_vm.badges),function(badge,index){return _c('UiBadge',_vm._b({key:("badge-" + index)},'UiBadge',Object.assign({}, badge),false),[(badge.icon)?_c('UiIcon',{attrs:{"name":badge.icon,"width":"24","height":"24"}}):_vm._e()],1)}),1):_vm._e(),(_vm.previewImages.length)?_c('UiCarousel',_vm._b({staticClass:"gallery-carousel",on:{"onSlideClick":_vm.onSlideClick,"onSlideChange":_vm.onSlideChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var onClick = ref.onClick;
return _vm._l((_vm.previewImages),function(previewImage,index){return _c('div',{key:index,staticClass:"d-flex flex-column",on:{"click":onClick}},[_c('UiImage',_vm._b({attrs:{"isLazy":false}},'UiImage',Object.assign({}, previewImage, {fallback: _vm.fallbackImage}),false))],1)})}}],null,false,1182979581)},'UiCarousel',Object.assign({}, _vm.mobileCarousel, {initialSlide: _vm.selectedImageIndex}),false)):_vm._e(),_c('div',{staticClass:"full-mobile",class:{'--active':_vm.isActiveFullScreenMobile}},[_c('button',{staticClass:"close-icon",on:{"click":_vm.deactivateFullScreenMobile}},[_c('UiIcon',{attrs:{"name":_vm.closeIcon}})],1),_c('UiImage',_vm._b({attrs:{"isLazy":false}},'UiImage',Object.assign({}, _vm.fullImage),false)),(_vm.thumbnails.length)?_c('div',{staticClass:"full-mobile__thumbnails-wrapper"},[_c('CustomThumbnails',{attrs:{"thumbnails":_vm.thumbnails,"direction":"horizontal","selectedImageIndex":_vm.selectedImageIndex},on:{"change":_vm.onThumbnailChange}})],1):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }